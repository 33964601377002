<template>
  <super-admin-page-card
    pageName="Authorization"
    iconClass="fa fa-gear fa-lg"
    >
    <div>
      <b-tabs>
        <b-tab active>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">By Company Type</h6>
          </template>
          <by-company-type></by-company-type>
        </b-tab>
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">By Group</h6>
          </template>
          <by-group></by-group>
        </b-tab>
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">By User</h6>
          </template>
          <by-user></by-user>
        </b-tab>
      </b-tabs>
    </div>
  </super-admin-page-card>
</template>

<script>
// base
import { mapGetters } from 'vuex'
import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'
//ui components
import ByCompanyType from './ByCompanyType'
import ByGroup from './ByGroup'
import ByUser from './ByUser'

export default {
  name: 'Authorization',
  components: {
    ByCompanyType,
    ByGroup,
    ByUser
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/manageSuperAdminBoard']) {
      next()
    } else {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('Ability', ['manageSuperAdminBoard'])
  },
  methods: {
  }
}
</script>
