import axios from 'axios'

export default {
  edit(id) {
    return axios
      .get(`/api/super_admin/company_type_permissions/${id}/edit`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  update(id, permissions) {
    return axios
      .put(
        `/api/super_admin/company_type_permissions/${id}`,
        {
          company_type_permissions: permissions
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  }
}
