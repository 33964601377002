import axios from 'axios'

export default {
  allUsers() {
    return axios
      .get(`/api/super_admin/users`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  },
  mockUser(userID) {
    return axios
      .get(`/api/super_admin/users/${userID}/mock_user`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  },
  reInviteUser(userID) {
    return axios
      .get(`/api/super_admin/users/${userID}/reinvite_user`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  },
  approveUser(userID, isApproved, roleId, groupId) {
    return axios
      .post(`/api/super_admin/users/${userID}/approve_user`, {
        is_approved: isApproved,
        ...(roleId ? { role_id: roleId } : {}),
        ...(groupId ? { group_id: groupId } : {}),
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  },
  set_fin_api_key() {
    return axios
      .post(`/api/super_admin/users/set_fin_api_key`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  allUsersData() {
    return axios
      .get('/api/users', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  verifyUser(userId) {
    return axios
      .get(`/api/super_admin/users/${userId}/verify_user`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  },
  fetchCAOptions(userId) {
    return axios
      .get(`/api/super_admin/users/${userId}/get_other_client_admins`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  }
}
