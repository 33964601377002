<template>
  <div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <small>
          <b>
            <sup class="">* </sup>
            NOTE:
          </b>
          Updating authorization will cause relevant group user's sessions to be
          reset.
        </small>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-sm-6">
        <b-form-group
          label="Group"
          label-for="group"
          :label-cols="4"
          :horizontal="true"
          class=""
        >
          <v-wait for="fetchingGroups">
            <template slot="waiting">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="2"
                ></content-placeholders-text>
              </content-placeholders>
            </template>
            <multiselect
              v-model="selectedGroup"
              :options="groups"
              :multiple="false"
              :showLabels="false"
              track-by="id"
              label="displayName"
              placeholder="Select Group"
              @select="fetchPermissions"
            >
            </multiselect>
          </v-wait>
        </b-form-group>
      </div>
    </div>

    <div class="row" v-if="selectedGroup">
      <div class="col-sm-12">
        <b-card class="std-border" body-class="p-1" header-class="p-2">
          <div slot="header">
            <div class="row fit-to-center">
              <div class="col-sm-12">
                <div class="d-flex">
                  <h5 class="mb-0 font-weight-bold">
                    {{ selectedGroup.name }}
                    <span class="font-weight-light"
                      >[{{ selectedGroup.id }}]</span
                    >
                    - Group Permissions
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div class="p-3">
            <div class="row">
              <div class="col-sm-6">
                <v-wait for="fetchingPermissions">
                  <template slot="waiting">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="10"
                      ></content-placeholders-text>
                    </content-placeholders>
                  </template>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(module, index) in parentModules"
                      :key="index"
                      class=""
                    >
                      <div class="row">
                        <div class="col-sm-10 text-left">
                          {{ module.name }}
                        </div>
                        <div class="col-sm-2 text-center pl-0">
                          <b-form-checkbox
                            v-model="module.granted"
                            @change="updateChild($event, module)"
                          ></b-form-checkbox>
                        </div>
                      </div>

                      <div class="row pt-2">
                        <div class="col-sm-12">
                          <b-list-group>
                            <b-list-group-item
                              v-for="(childModule, index) in childModules(
                                module
                              )"
                              :key="index"
                              class="child-modules"
                            >
                              <div class="row">
                                <div class="col-sm-10 text-left">
                                  {{ childModule.name }}
                                </div>
                                <div class="col-sm-2 text-right">
                                  <b-form-checkbox
                                    v-model="childModule.granted"
                                    @change="
                                      updateParent($event, module, childModule)
                                    "
                                  ></b-form-checkbox>
                                </div>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </v-wait>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-sm-6 text-center">
                <b-button
                  size=""
                  variant="secondary"
                  class="fw-btn mt-3 mx-3"
                  @click="revokeAllPermissions()"
                >
                  Revoke All
                </b-button>
                <b-button
                  size=""
                  variant="secondary"
                  class="fw-btn mt-3 mx-3"
                  @click="resetPermissions()"
                >
                  Reset
                </b-button>
                <b-button
                  size=""
                  variant="primary"
                  class="fw-btn mt-3 mx-3"
                  @click="updatePermissions()"
                >
                  Update
                </b-button>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
//plugin ui components
import Multiselect from 'vue-multiselect'
//api
import groupsAPI from '@/api/finapps/super_admin/groups'
import groupPermissionsAPI from '@/api/finapps/super_admin/group_permissions'

export default {
  name: 'ByGroup',
  components: {
    Multiselect
  },
  data() {
    return {
      groups: [],
      modulePermissions: [],
      selectedGroup: null
    }
  },
  created() {
    this.getAllGroups()
  },
  computed: {
    parentModules() {
      return this.modulePermissions.filter(m => m.parent_id === null)
    },
    isHomeEnabled() {
      return this.modulePermissions.find(m => m.name === 'Home' && m.granted)
    },
    revokeGroupPermission() {
      return !this.modulePermissions.find(m => m.granted)
    }
  },
  methods: {
    getAllGroups() {
      this.$wait.start('fetchingGroups')
      groupsAPI
        .all()
        .then(res => {
          this.groups = res.groups
          this.groups.forEach(grp => {
            grp['displayName'] = grp.name + ' [' + grp.id + ']'
          })
        })
        .finally(() => {
          this.$wait.end('fetchingGroups')
        })
    },
    fetchPermissions(companyType) {
      this.$wait.start('fetchingPermissions')

      groupPermissionsAPI
        .edit(companyType.id)
        .then(res => {
          this.modulePermissions = res.module_permissions
        })
        .finally(() => {
          this.$wait.end('fetchingPermissions')
        })
    },
    childModules(parentModule) {
      return this.modulePermissions.filter(
        mp => mp.parent_id === parentModule.id
      )
    },
    updateChild(checked, module) {
      let childModules = this.childModules(module)
      childModules.forEach(module => {
        module.granted = checked
      })
    },
    updateParent(checked, parentModule, childModule) {
      if (checked) {
        parentModule.granted = true
      } else {
        let siblingModules = this.modulePermissions.filter(mp => {
          return mp.parent_id === parentModule.id && mp.id !== childModule.id
        })
        let hasEnabledSibling = siblingModules.find(sm => sm.granted === true)
        if (!hasEnabledSibling) {
          parentModule.granted = false
        }
      }
    },
    updatePermissions() {
      if (this.revokeGroupPermission || this.isHomeEnabled) {
        let permissions = this.modulePermissions.map(mp => {
          return {
            module_id: mp.id,
            granted: mp.granted || false
          }
        })
        groupPermissionsAPI
          .update(this.selectedGroup.id, permissions)
          .then(() => {
            this.$toasted.show(
              "Permissions updated successfully and active user's session cleared."
            )
          })
      } else {
        this.$toasted.global.validation_error(
          'Home module permission is mandatory.'
        )
      }
    },
    resetPermissions() {
      this.fetchPermissions(this.selectedGroup)
    },
    revokeAllPermissions() {
      groupPermissionsAPI.delete(this.selectedGroup.id).then(() => {
        this.fetchPermissions(this.selectedGroup)
        this.$toasted.show(
          "Group Permissions revoked successfully and active user's session cleared."
        )
      })
    }
  }
}
</script>

<style lang="scss">
.child-modules {
  background-color: #f0f3f5;
}
</style>
